import { useContext, useState } from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { AppSelector, COLORS } from "@stacklet/ui";
import { graphql } from "react-relay";
import { useLazyLoadQuery } from "react-relay/hooks";
import { NavLink, useLocation } from "react-router-dom";

import { SidebarIcon } from "app/components/icons/SidebarIcon";
import { ProviderSelector } from "app/components/ProviderSelector";
import { SideBarContext, useConfig } from "app/contexts";
import { useHasRequiredPermission } from "app/hooks";
import { ADMIN_PERMISSIONS, ADMIN_PERMISSIONS_DEMO } from "app/utils/consts";
import expandIcon from "assets/images/open_close_button_right.svg";
import stackLetLogoCollapse from "assets/images/stacklet_logo.svg";

import NavBarMenu from "../NavBarMenu";
import CollapseIcon from "./components/CollapseIcon";
import SidebarLink from "./components/SidebarLink";
import { containsCurrentRoute, useNavItems } from "./hooks";

import type { SideBarQuery } from "./__generated__/SideBarQuery.graphql";
import type { NavItem } from "./hooks";
import type { Provider } from "app/contexts/ProviderContext";

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  width: "100%",
  padding: 0,
  margin: 0,
  height: "unset",
  border: "none",
  minHeight: "unset",
  "&.level-1": {
    paddingLeft: theme.spacing(4),
  },
  "&.level-2": {
    paddingLeft: theme.spacing(7),
  },
  "&.Mui-expanded": {
    minHeight: "unset",
  },
  "& .MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
  "& .MuiButtonBase-root": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "&.activeTab": {
    backgroundColor: COLORS.navy.L10,
  },
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.dark,
  padding: 0,
  border: "none",
}));

const SideBarRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: `0 0 ${theme.sidebar.width}`,
  height: "100vh",
  "&.collapsed": {
    width: theme.sidebar.widthCollapsed,
    flex: 0,
    "& .toggleIconWrapper": {
      width: theme.sidebar.widthCollapsed,
    },
    "& .drawer": {
      display: "none",
    },
  },
  "& .drawer": {
    "& .MuiDrawer-paper": {
      width: theme.sidebar.width,
      background: theme.palette.secondary.dark,
      color: theme.palette.common.white,
      position: "relative",
    },
  },
}));

const StyledNavLinkCollapse = styled(NavLink)(({ theme }) => ({
  "&.active": {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: COLORS.navy.L10,
  },
  "& activeSubCollapseBar": {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: COLORS.navy.L10,
  },
}));

const HorizontalLine = styled("hr")(({ theme }) => ({
  borderColor: "#646B72",
  width: "80%",
  marginTop: 0,
  marginBottom: theme.spacing(2),
}));

const MenuNavItems = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: "1",
  overflowY: "scroll",
  overflowX: "hidden",
  "&::-webkit-scrollbar": {
    display: "none" /* Chrome Safari */,
  },
});

interface MenuListProps {
  data: NavItem;
  nestedLevel: number;
}

const NavItemList = ({ data, nestedLevel }: MenuListProps): JSX.Element => {
  const location = useLocation();

  let shouldBeExpanded = false;
  if (data.children) {
    shouldBeExpanded = containsCurrentRoute({
      currentRoute: location.pathname,
      navItems: data.children,
    });
  }

  const [expanded, setExpanded] = useState(shouldBeExpanded);

  return data.children ? (
    <Accordion
      expanded={expanded}
      sx={{
        color: `common.white`,
        margin: 0,
        "&.Mui-expanded": {
          margin: 0,
        },
        border: "none",
      }}
    >
      <StyledAccordionSummary
        className={`level-${nestedLevel} ${
          shouldBeExpanded ? "activeTab" : ""
        }`}
        expandIcon={
          <ArrowDropDownIcon
            sx={{
              padding: 0,
              my: 0,
              mx: 2,
              color: `common.white`,
            }}
          />
        }
        onClick={() => setExpanded(!expanded)}
      >
        <Box alignItems="center" display="flex">
          <Box
            alignItems="center"
            display="flex"
            justifyContent="flex-start"
            sx={{
              width: "100%",
              py: 1.5,
              px: 3,
              borderLeft: "2px solid transparent",
            }}
          >
            {nestedLevel === 0 ? (
              <Box alignItems="center" display="flex" sx={{ pr: 2 }}>
                <SidebarIcon title={data.id} />
              </Box>
            ) : (
              ""
            )}
            <Typography variant={nestedLevel === 0 ? "h6" : "body1"}>
              {data.label}
            </Typography>
          </Box>
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails className={`level-${nestedLevel}`}>
        {data.children.map((navitem: NavItem) => (
          <NavItemList
            data={navitem}
            key={navitem.id}
            nestedLevel={nestedLevel + 1}
          />
        ))}
      </StyledAccordionDetails>
    </Accordion>
  ) : (
    <SidebarLink
      isExternalLink={data.isExternalLink}
      nestedLevel={nestedLevel}
      target={data.target}
    >
      <>
        {nestedLevel === 0 ? (
          <Box alignItems="center" display="flex" pr={2}>
            <SidebarIcon title={data.id} />
          </Box>
        ) : (
          ""
        )}
        <Typography variant={nestedLevel === 0 ? "h6" : "body1"}>
          {data.label}
        </Typography>
      </>
    </SidebarLink>
  );
};

interface Props {
  onProviderChange: (provider: Provider) => void;
}

export default function SideBar({ onProviderChange }: Props) {
  const config = useConfig();
  const location = useLocation();
  const { collapsed, toggleCollapsed } = useContext(SideBarContext);
  const hasPermission = useHasRequiredPermission();
  const isAdmin = hasPermission(ADMIN_PERMISSIONS);
  const isDemo = hasPermission(ADMIN_PERMISSIONS_DEMO);

  const data = useLazyLoadQuery<SideBarQuery>(
    graphql`
      query SideBarQuery {
        UrlConfig {
          jun0
          redash
          sinistral
        }
      }
    `,
    {},
  );

  const appOptions = [
    {
      label: "AssetDB",
      href: data.UrlConfig?.redash || "https://redash.dev.stacklet.dev/",
      isBeta: false,
    },
    {
      label: "Console",
      href: "",
      isBeta: false,
    },
    ...(config?.dev_flags?.includes("jun0")
      ? [
          {
            label: "Jun0",
            href: data.UrlConfig?.jun0 || "https://jun0.dev.stacklet.dev/",
            isBeta: true,
          },
        ]
      : []),

    ...(data.UrlConfig?.sinistral
      ? [
          {
            label: "IaC Governance",
            href: data.UrlConfig?.sinistral,
            isBeta: false,
          },
        ]
      : []),
  ];

  const settingsAndTools = [
    ...(isAdmin || isDemo
      ? [
          {
            label: "Settings",
            id: "settings",
            children: [
              {
                label: "Integrations",
                id: "integrations",
                target: "/settings/integrations",
              },
              {
                label: "Policy repositories",
                id: "settings-repositories",
                target: "/settings/repositories",
              },
              {
                label: "Users",
                id: "settings-users",
                target: "/settings/users",
              },
            ],
          },
        ]
      : []),
    {
      label: "Stacklet Tools",
      id: "stacklet-tools",
      children: [
        {
          label: "AssetDB",
          target: data.UrlConfig?.redash || "https://redash.dev.stacklet.dev/",
          isExternalLink: true,
          id: "redash-assetdb",
        },
        ...(config?.dev_flags?.includes("jun0")
          ? [
              {
                label: "Jun0 (Beta)",
                target:
                  data.UrlConfig?.jun0 || "https://jun0.dev.stacklet.dev/",
                isExternalLink: true,
                id: "jun0",
              },
            ]
          : []),
        ...(data.UrlConfig?.sinistral
          ? [
              {
                label: "IaC Governance",
                target: data.UrlConfig?.sinistral,
                isExternalLink: true,
                id: "sinistral",
              },
            ]
          : []),
        {
          label: "Stacklet API",
          target: "/api",
          isExternalLink: false,
          id: "gql-api",
        },
      ],
    },
  ];

  const [items, collapsedItems, bottomItems, collapsedBottomItems] =
    useNavItems(settingsAndTools);

  const nestedLevel = 0;

  return (
    <SideBarRoot>
      <Drawer
        className={"drawer"}
        sx={{ flex: "1 0 auto" }}
        variant="persistent"
        open
      >
        <Toolbar sx={{ py: 0, px: 3, display: "inline-block" }}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            sx={{ py: 2, px: 0 }}
          >
            <NavLink to="/">
              <Box display="flex" justifyContent="center">
                <img
                  alt="Stacklet logo"
                  height="24"
                  src={stackLetLogoCollapse}
                />
              </Box>
            </NavLink>

            <AppSelector appOptions={appOptions} selected="Console" />
          </Box>
        </Toolbar>
        <HorizontalLine />

        <ProviderSelector onChange={onProviderChange} />

        <MenuNavItems sx={{ overflow: "auto" }}>
          {items.map((navitem) => (
            <NavItemList
              data={navitem}
              key={navitem.id}
              nestedLevel={nestedLevel}
            />
          ))}
        </MenuNavItems>
        <HorizontalLine />
        <Box
          sx={{
            mt: 1,
            display: "inline-block",
          }}
        >
          <MenuNavItems>
            {bottomItems.map((navitem) => (
              <NavItemList
                data={navitem}
                key={navitem.id}
                nestedLevel={nestedLevel}
              />
            ))}
          </MenuNavItems>
        </Box>
        <Box
          sx={{
            py: 2,
            px: 3,
            mt: 1,
            display: "inline-block",
            backgroundColor: COLORS.navy.D50,
          }}
        >
          <NavBarMenu />
        </Box>
      </Drawer>
      {collapsed ? (
        <Box
          display="flex"
          flexDirection="column"
          sx={{ backgroundColor: `secondary.main` }}
        >
          <Box display="flex" flexDirection="column">
            <CollapseIcon>
              <NavLink to="/">
                <img
                  alt="Stacklet logo"
                  height="24"
                  src={stackLetLogoCollapse}
                />
              </NavLink>
            </CollapseIcon>
            <CollapseIcon onClick={toggleCollapsed}>
              <img
                alt="Expand Button"
                height="24"
                src={expandIcon}
                width="28"
              />
            </CollapseIcon>
          </Box>
          <HorizontalLine />

          <MenuNavItems>
            {collapsedItems.map((navItem) => {
              const regex = new RegExp(`${navItem.mainPath}/*`);
              return (
                <Box key={navItem.id}>
                  {navItem.isExternalLink ? (
                    <CollapseIcon onClick={toggleCollapsed} isMainCollapsed>
                      <SidebarIcon title={navItem.id} />
                    </CollapseIcon>
                  ) : (
                    <StyledNavLinkCollapse
                      className={
                        regex.test(location.pathname)
                          ? "activeSubCollapseBar"
                          : undefined
                      }
                      to={navItem.target || ""}
                    >
                      <CollapseIcon isMainCollapsed>
                        <SidebarIcon title={navItem.id} />
                      </CollapseIcon>
                    </StyledNavLinkCollapse>
                  )}
                </Box>
              );
            })}
          </MenuNavItems>

          <Box>
            <MenuNavItems>
              {collapsedBottomItems.map((navItem) => {
                const regex = new RegExp(`${navItem.mainPath}/*`);
                return (
                  <StyledNavLinkCollapse
                    className={
                      regex.test(location.pathname)
                        ? "activeSubCollapseBar"
                        : undefined
                    }
                    key={navItem.id}
                    to={navItem.target || ""}
                  >
                    <CollapseIcon isMainCollapsed>
                      <SidebarIcon title={navItem.id} />
                    </CollapseIcon>
                  </StyledNavLinkCollapse>
                );
              })}
            </MenuNavItems>
            <CollapseIcon isMainCollapsed>
              <NavBarMenu collapsed />
            </CollapseIcon>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </SideBarRoot>
  );
}
